import { styled } from 'libs/aether/config/stitches';

export const ComicList = styled('div', {
  width: '90%',
  maxWidth: '1130rem',
  margin: '$space$none auto $space$large',
});

export const Grid = styled('div', {
  display: 'grid',
  gridTemplateColumns: 'repeat(2, 1fr)',
  '@bp1': {
    gridTemplateColumns: 'repeat(3, 1fr)',
  },
  '@bp2': {
    gridTemplateColumns: 'repeat(4, 1fr)',
  },
  '@bp3': {
    gridTemplateColumns: 'repeat(5, 1fr)',
  },
});

export const Pagination = styled('div', {
  display: 'grid',
  gridTemplateColumns: 'repeat(2, 1fr)',
  gap: '$space$none $space$large',
  marginTop: '$space$large',

  '> div': {
    display: 'flex',
    '@bp1': {
      '>button': {
        width: '210rem',
      },
    },

    '&:first-of-type': {
      justifyContent: 'flex-start',
    },

    '&:last-of-type': {
      justifyContent: 'flex-end',
    },
  },
});
