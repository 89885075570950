import md5 from 'md5';

export const generateAuth = () => {
  const PRIVATE_KEY = process.env.REACT_APP_MARVEL_PRIVATE_KEY || '';
  const PUBLIC_KEY = process.env.REACT_APP_MARVEL_PUBLIC_KEY || '';

  const timestamp = Number(new Date());
  const hash = md5(timestamp + PRIVATE_KEY + PUBLIC_KEY);

  return `ts=${timestamp}&apikey=${PUBLIC_KEY}&hash=${hash}`;
};
