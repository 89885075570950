import { styled } from 'libs/aether/config/stitches';

export const Header = styled('header', {
  // TODO: find some way to do this with stitches tokens
  backgroundColor: 'rgba(237, 35, 36, .9)',
  position: 'fixed',
  zIndex: 1,
  top: 0,
  left: 0,
  width: '100%',
  height: '150rem',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  gap: '20rem 0',
});

export const Form = styled('form', {
  width: '95%',
  maxWidth: '440rem',
});
