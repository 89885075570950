import {
  ReactNode,
  createContext,
  useState,
  Dispatch,
  SetStateAction,
} from 'react';

type searchCharacterProps = {
  characterId?: number;
};

type SearchProviderProps = {
  children: ReactNode;
};

type SearchContextProps = {
  characterId?: number;
  offset: number;
  setOffset: (newOffset: number) => void;
  searchCharacter: (props: searchCharacterProps) => void;
  characterNotFound: boolean;
  setCharacterNotFound: Dispatch<SetStateAction<boolean>>;
};

const SearchContext = createContext<SearchContextProps>({
  characterId: undefined,
  offset: 0,
  setOffset: () => null,
  searchCharacter: () => null,
  characterNotFound: false,
  setCharacterNotFound: () => null,
});

const SearchProvider = ({ children }: SearchProviderProps) => {
  const [characterNotFound, setCharacterNotFound] = useState(false);
  const [characterId, setCharacterId] = useState<number | undefined>(undefined);
  const [offset, setOffset] = useState(0);

  const searchCharacter = ({ characterId }: searchCharacterProps) => {
    setOffset(0);
    setCharacterNotFound(false);
    setCharacterId(characterId);
  };

  return (
    <SearchContext.Provider
      value={{
        characterId,
        offset,
        setOffset,
        searchCharacter,
        characterNotFound,
        setCharacterNotFound,
      }}
    >
      {children}
    </SearchContext.Provider>
  );
};

export { SearchProvider, SearchContext };
