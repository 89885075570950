import { global } from 'libs/aether/config/stitches';

const globalStyles = global({
  '*': {
    margin: '$space$none',
    padding: '$space$none',
    boxSizing: 'border-box',
  },
  html: {
    fontFamily: '$fonts$primary',
    fontSize: '6.25%',
  },
});

export default globalStyles;
