import { ReactNode } from 'react';
import {
  QueryClient,
  QueryClientProvider as QueryClientProviderBase,
} from 'react-query';

type QueryClientProviderProps = {
  children: ReactNode;
};

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: false,
      refetchOnWindowFocus: false,
    },
  },
});

export const QueryClientProvider = ({ children }: QueryClientProviderProps) => {
  return (
    <QueryClientProviderBase client={queryClient}>
      {children}
    </QueryClientProviderBase>
  );
};
