import thorImageUrl from 'assets/thor.png';
import * as S from './SearchFallback.styled';

const SearchFallback = () => {
  return (
    <S.SearchFallback>
      <S.Title>
        Thor&apos;s Hammer smashed our search, we couldn&apos;t find what you
        searched for
      </S.Title>
      <S.Image src={thorImageUrl} />
    </S.SearchFallback>
  );
};

export default SearchFallback;
