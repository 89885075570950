import { InputHTMLAttributes } from 'react';
import * as S from './Input.styled';

type InputProps = {
  label: string;
  fullWidth?: boolean;
} & InputHTMLAttributes<HTMLInputElement>;

const Input = ({ label, fullWidth = false, ...props }: InputProps) => {
  return (
    <S.Input
      {...props}
      aria-label={label}
      placeholder={label}
      fullWidth={fullWidth}
    />
  );
};

export default Input;
