import api from 'config/api';
import { useQuery } from 'react-query';

import { generateAuth } from 'utils/generateAuth';

type getCharacterIdProps = {
  characterName: string;
};

type useGetCharacterIdProps = {
  characterName: string;
};

type Response = {
  id: number;
};

const getCharacterId = async ({ characterName }: getCharacterIdProps) => {
  const response = await api.get(
    `v1/public/characters?name=${characterName}&${generateAuth()}`
  );

  return response.data.data?.results[0];
};

const useGetCharacterId = ({ characterName }: useGetCharacterIdProps) => {
  return useQuery<Response>(
    ['getGetCharacterId', characterName],
    () => getCharacterId({ characterName }),
    {
      enabled: false,
    }
  );
};

export default useGetCharacterId;
