import { styled } from 'libs/aether/config/stitches';

export const SearchFallback = styled('div', {
  paddingTop: '$space$large',
  margin: 'auto',
  width: '90%',
  maxWidth: '800rem',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
});

export const Title = styled('h1', {
  textAlign: 'center',
  fontSize: '$fontSizes$large',
  marginBottom: '$space$huge',
});

export const Image = styled('img', {
  maxWidth: '300rem',
});
