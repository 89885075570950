import * as S from './Loader.styled';

const Loader = () => {
  return (
    <S.Wrapper>
      <S.Loader aria-label="loading..." />
      <S.Overlay />
    </S.Wrapper>
  );
};

export default Loader;
