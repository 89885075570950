import { useMemo, useState } from 'react';

import { Comic } from 'types/Comic';

type useUpvoteProps = {
  comicList?: Comic[];
};

const useUpvote = ({ comicList: originalComicList }: useUpvoteProps) => {
  const [favoriteComics, setFavoriteComics] = useState<number[]>([]);
  const comicList = useMemo(() => {
    return originalComicList?.map((comic) => ({
      ...comic,
      upvote: favoriteComics.includes(comic.id),
    }));
  }, [originalComicList, favoriteComics]);

  const upvote = ({ comicId }: { comicId: number }) => {
    setFavoriteComics((prev) => Array.from(new Set([...prev, comicId])));
  };

  const downvote = ({ comicId }: { comicId: number }) => {
    setFavoriteComics((prev) =>
      prev.filter((favoriteComic) => favoriteComic !== comicId)
    );
  };

  const toggleUpvote = ({ comicId }: { comicId: number }) => {
    if (favoriteComics.includes(comicId)) {
      downvote({ comicId });
    } else {
      upvote({ comicId });
    }
  };

  return {
    comicList,
    toggleUpvote,
  };
};

export default useUpvote;
