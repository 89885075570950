import { styled } from '../../config/stitches';

export const Button = styled('button', {
  cursor: 'pointer',
  border: '$borderWidths$none',
  backgroundColor: '$colors$neutral100',
  color: '$colors$primary',
  padding: '$space$small',
  fontSize: '$fontSizes$large',
  textTransform: 'uppercase',
  transition: '$transitions$default',

  '&:disabled': {
    backgroundColor: '$colors$neutral200',
    opacity: '0.7',
    cursor: 'not-allowed',
  },

  '&:hover:not(:disabled)': {
    boxShadow: '$shadows$default',
  },
});
