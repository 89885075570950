import { createCss } from '@stitches/react';
export { global } from '@stitches/react';

export const { styled } = createCss({
  theme: {
    fontSizes: {
      default: '14rem',
      large: '21rem',
    },
    fonts: {
      primary: 'sans-serif',
    },
    colors: {
      primary: '#ed2324',
      neutral100: '#ffffff',
      neutral200: '#959595',
      neutral900: '#000000',
    },
    space: {
      none: '0',
      small: '12rem',
      large: '20rem',
      huge: '40rem',
    },
    transitions: {
      fast: '.1s ease-in',
      default: '.2s ease-in',
      slow: '.3s ease-in',
    },
    shadows: {
      default: '2rem 2rem 1rem 0 #202020',
      focus: '0 0 0 2rem  $colors$primary',
    },
    borderWidths: {
      none: '0',
      medium: '10rem',
    },
    radii: {
      none: '0',
      default: '4rem',
      rounded: '200rem',
    },
  },
  media: {
    bp1: '(min-width: 640px)',
    bp2: '(min-width: 768px)',
    bp3: '(min-width: 1024px)',
  },
});
