import { useContext } from 'react';

import ComicList from 'components/ComicList';
import SearchBar from 'components/SearchBar';
import SearchFallback from 'components/SearchFallback';
import { SearchContext } from 'context/Search';

const Search = () => {
  const { characterNotFound } = useContext(SearchContext);

  return (
    <>
      <SearchBar />
      {characterNotFound ? <SearchFallback /> : <ComicList />}
    </>
  );
};

export default Search;
