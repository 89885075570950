import { styled } from 'libs/aether/config/stitches';
import { SearchProvider } from 'context/Search';
import SearchPage from 'pages/Search';

const Main = styled('main', {
  marginTop: '170rem',
});

function App() {
  return (
    <Main>
      <SearchProvider>
        <SearchPage />
      </SearchProvider>
    </Main>
  );
}

export default App;
