import { ReactNode, MouseEvent } from 'react';

import * as S from './Button.styled';

type ButtonProps = {
  children: ReactNode;
  onClick?: (event: MouseEvent<HTMLButtonElement>) => void;
  loading?: boolean;
  disabled?: boolean;
};

const Button = ({
  children,
  onClick,
  loading = false,
  disabled = false,
}: ButtonProps) => {
  return (
    <S.Button onClick={onClick} disabled={disabled}>
      {loading ? 'loading...' : children}
    </S.Button>
  );
};

export default Button;
