import { styled } from 'libs/aether/config/stitches';

import HeartOn from './assets/heart-on.png';
import HeartOff from './assets/heart-off.png';
import HeartHover from './assets/heart-hover.png';

export const Card = styled('div', {
  width: 'auto',
  position: 'relative',
  overflowY: 'hidden',
  transition: '$transitions$fast',

  variants: {
    actived: {
      true: {
        border: '$borderWidths$medium solid $colors$primary',
      },
      false: {
        border: '$borderWidths$medium solid transparent',
      },
    },
  },
});

export const TitleWrapper = styled('div', {
  position: 'absolute',
  bottom: '0',
  background: '$colors$neutral100',
  transition: '$transitions$slow',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  height: '55rem',
  width: '100%',

  variants: {
    visibility: {
      true: {
        opacity: 1,
        visibility: 'visible',
      },
      false: {
        opacity: 0,
        transform: 'translateY(100%)',
        visibility: 'hidden',
      },
    },
  },
});

export const Title = styled('h2', {
  fontSize: '$fontSizes$default',
  color: '$colors$neutral900',
});

export const UpvoteButton = styled('button', {
  cursor: 'pointer',
  backgroundColor: 'transparent',
  border: '$borderWidths$none',
  position: 'absolute',
  top: '$space$small',
  right: '$space$small',
  width: '40rem',
  height: '40rem',
  backgroundPosition: 'center',
  backgroundRepeat: 'no-repeat',

  variants: {
    visibility: {
      upvoted: {
        backgroundImage: `url(${HeartOn})`,
        '&:hover': {
          backgroundImage: `url(${HeartHover})`,
        },
      },
      notUpvoted: {
        backgroundImage: `url(${HeartOff})`,
        '&:hover': {
          backgroundImage: `url(${HeartHover})`,
        },
      },
    },
  },
});

export const Img = styled('img', {
  width: '100%',
  maxWidth: '100%',
  height: '100%',
  objectFit: 'cover',
});
