import api from 'config/api';
import { useQuery } from 'react-query';

import { Comic } from 'types/Comic';
import { generateAuth } from 'utils/generateAuth';

type PaginationProps = {
  offset: number;
};

type useGetAllComicsProps = PaginationProps & {
  characterId?: number;
};

type getGetAllComicsProps = PaginationProps & {
  characterId?: number;
};

type Response = {
  count: number;
  limit: number;
  offset: number;
  total: number;
  results?: Comic[];
};

// TODO: i'm not sure about what orderBy option i should use to list from the most recents
const getGetAllComics = async ({
  offset,
  characterId,
}: getGetAllComicsProps) => {
  const serviceUrl = characterId
    ? `v1/public/characters/${characterId}/comics`
    : 'v1/public/comics';

  const response = await api.get(
    `${serviceUrl}?${generateAuth()}&orderBy=-issueNumber&limit=10&offset=${offset}`
  );

  return response.data.data;
};

const useGetAllComics = ({ offset, characterId }: useGetAllComicsProps) => {
  return useQuery<Response>(
    ['getGetAllComics', offset, characterId],
    () => getGetAllComics({ offset, characterId }),
    {
      keepPreviousData: true,
    }
  );
};

export default useGetAllComics;
