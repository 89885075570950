import * as S from './ErrorFallback.styled';
import ErrorImageUrl from 'assets/error-fallback.png';

const Error = () => {
  return (
    <S.Wrapper>
      <S.Image src={ErrorImageUrl} />
      <S.Message>oops... something went wrong :(</S.Message>
    </S.Wrapper>
  );
};

export default Error;
