import { FormEvent, useContext, useEffect, useState } from 'react';

import logoUrl from 'assets/logo.png';
import { Input } from 'libs/aether/components';
import { SearchContext } from 'context/Search';

import * as S from './SearchBar.styled';
import useGetCharacterId from 'services/useGetCharacterId';

const SearchBar = () => {
  const { searchCharacter, setCharacterNotFound } = useContext(SearchContext);
  const [searchTerm, setSearchTerm] = useState('');
  const { data, isLoading, refetch, status } = useGetCharacterId({
    characterName: searchTerm,
  });

  useEffect(() => {
    if (status === 'idle') return;

    if (data) {
      searchCharacter({ characterId: data.id });
    } else {
      setCharacterNotFound(true);
    }
  }, [data, status]);

  const handleSubmit = (event: FormEvent) => {
    event.preventDefault();
    refetch();
  };

  return (
    <S.Header>
      <img src={logoUrl} alt="Marvel Logo" />
      <S.Form onSubmit={handleSubmit}>
        <Input
          disabled={isLoading}
          label="enter character name"
          fullWidth
          value={searchTerm}
          onChange={(event) => {
            setSearchTerm(event.target.value);
          }}
        />
      </S.Form>
      <div />
    </S.Header>
  );
};

export default SearchBar;
