import { keyframes } from '@stitches/react';
import { styled } from '../../config/stitches';

const pulse = keyframes({
  '0%': { transform: 'scale(0.7)' },
  '70%': { transform: 'scale(1.1)' },
  '100%': { transform: 'scale(0.7)' },
});

export const Wrapper = styled('div', {
  position: 'fixed',
  top: 0,
  left: 0,
  width: '100vw',
  height: '100vh',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
});

export const Loader = styled('div', {
  position: 'absolute',
  width: '50rem',
  height: '50rem',
  border: '2rem solid $colors$primary',
  borderRadius: '$radii$rounded',
  animation: `${pulse} 1s infinite`,
});

export const Overlay = styled('div', {
  width: '100%',
  height: '100%',
  backgroundColor: 'rgba(0, 0, 0, 0.7)',
});
