import { styled } from 'libs/aether/config/stitches';

export const Wrapper = styled('div', {
  margin: 'auto',
  maxWidth: '500rem',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
});

export const Message = styled('span', {
  fontSize: '$fontSizes$large',
});

export const Image = styled('img', {
  maxWidth: '100%',
});
