import { useContext } from 'react';

import ComicCard from 'components/ComicCard';
import ErrorFallback from 'components/ErrorFallback';
import { Button, Loader } from 'libs/aether/components';
import useGetAllComics from 'services/useGetAllComics';
import useUpvote from 'services/useUpvote';
import { SearchContext } from 'context/Search';

import * as S from './ComicList.styled';

const ComicList = () => {
  const { offset, setOffset, characterId } = useContext(SearchContext);
  const { data, error, isLoading, isFetching } = useGetAllComics({
    offset,
    characterId,
  });
  const { comicList, toggleUpvote } = useUpvote({ comicList: data?.results });

  if (isLoading) {
    return <Loader />;
  }

  if (error || !data?.results) {
    return <ErrorFallback />;
  }

  const hasPrev = offset > 0;

  const hasNext = offset < data.total;

  const goPrevPage = () => {
    setOffset(Math.max(offset - 10, 0));
  };

  const goNextPage = () => {
    setOffset(Math.min(offset + 10, data.total));
  };

  return (
    <S.ComicList>
      <S.Grid>
        {comicList?.map((comic) => (
          <ComicCard
            key={comic.id}
            imageUrl={comic.thumbnail.path + '.' + comic.thumbnail.extension}
            imageDescription={comic.title}
            title={comic.title}
            upvoted={comic.upvote}
            setUpvoted={() => toggleUpvote({ comicId: comic.id })}
          />
        ))}
      </S.Grid>
      <S.Pagination>
        <div>
          <Button
            onClick={goPrevPage}
            disabled={!hasPrev || isFetching}
            loading={isFetching}
          >
            Previous Page
          </Button>
        </div>
        <div>
          <Button
            onClick={goNextPage}
            disabled={!hasNext || isFetching}
            loading={isFetching}
          >
            Next Page
          </Button>
        </div>
      </S.Pagination>
    </S.ComicList>
  );
};

export default ComicList;
