import { styled } from '../../config/stitches';

export const Input = styled('input', {
  color: '$colors$neutral200',
  border: '1rem solid $colors$neutral200',
  padding: '$space$small',
  outline: 'none',
  borderRadius: '$radii$default',

  variants: {
    fullWidth: {
      true: {
        width: '100%',
      },
    },
  },

  '&:focus': {
    boxShadow: '$shadows$focus',
  },
});
