import { useState } from 'react';

import * as S from './ComicCard.styled';

type CardProps = {
  imageUrl: string;
  imageDescription: string;
  title: string;
  upvoted: boolean;
  setUpvoted: (state: boolean) => void;
};

const ComicCard = ({
  imageUrl,
  imageDescription,
  title,
  upvoted,
  setUpvoted,
}: CardProps) => {
  const [showDetails, setShowDetails] = useState(false);

  return (
    <S.Card
      actived={showDetails}
      onMouseEnter={() => setShowDetails(true)}
      onMouseLeave={() => setShowDetails(false)}
    >
      {showDetails && (
        <S.UpvoteButton
          aria-label={
            upvoted ? `remove upvote from ${title}` : `upvote ${title}`
          }
          visibility={upvoted ? 'upvoted' : 'notUpvoted'}
          onClick={() => setUpvoted(!upvoted)}
        />
      )}
      <S.TitleWrapper visibility={showDetails}>
        <S.Title>{title}</S.Title>
      </S.TitleWrapper>
      <S.Img src={imageUrl} alt={imageDescription} />
    </S.Card>
  );
};

export default ComicCard;
